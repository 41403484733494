import React, { Suspense, useEffect } from 'react';

import { RecoilRoot } from 'recoil';
import { CircularProgress, Stack } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

import { AuthButton } from './components/Auth';
import { ApiError, NavButton } from './Common';
import { useLogout } from './api/Auth';
import { useRefreshBatchList } from './api/BatchApi';

function NavBar(): JSX.Element {
  const refreshBatchList = useRefreshBatchList();

  return <Stack spacing={2} direction="row" p={2}>
    <NavButton to="/upload" authRequired>Upload Loans</NavButton>
    <NavButton to="/batches" authRequired>
      <Stack direction="row">
        View Modeled Batches
        <RefreshIcon onClick={refreshBatchList} sx={{ml: '15px'}} />
      </Stack>
    </NavButton>
    <AuthButton />
  </Stack>;
}

function BreadCrumbs(): JSX.Element {
  return <div />;
}

function ErrorFallback({error, resetErrorBoundary} : FallbackProps) {
  const doLogout = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    if (error instanceof ApiError && error.httpStatus === 401) {
      // On Auth errors, automatically log out, navigate back to /, and reset the error.
      doLogout();
      navigate('/', { state: { resetErrorBoundary }});
    }
  }, [doLogout, navigate, resetErrorBoundary, error]);

  return <div>
    <h1>{error.message}</h1>
  </div>;
}

function MainContent() {
  return <Suspense fallback={<CircularProgress />}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Outlet />
    </ErrorBoundary>
  </Suspense>
}

function App(): JSX.Element {
  const { state } = useLocation();
  useEffect(() => {
    // In the event of an error, we'll be passed a resetErrorBoundary callback via state.
    (state as any)?.resetErrorBoundary?.();
  }, [state]);
  
  return <RecoilRoot>
    <NavBar />
    <hr />
    <BreadCrumbs />
    <MainContent />
  </RecoilRoot>;
}

export default App;
