import React, { useCallback, useEffect, useState, ReactNode } from 'react';

import axios from 'axios';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authHeadersAtom, usernameAtom } from './api/Auth';

type NavButtonProps = {
  authRequired?: boolean;
  children: ReactNode;
  to: string;
};

export function NavButton({authRequired, children, to}: NavButtonProps): JSX.Element {
  const disabled = !useRecoilValue(usernameAtom) && authRequired;
  return <Button disabled={disabled} component={Link} to={to} size="large" variant="outlined">
    {children}
  </Button>;
}

function parseValueFromStorage<StoredType>(storedValue: string | null): StoredType | undefined {
  try {
    return JSON.parse(storedValue || '') as StoredType;
  } catch (error) {
    if (storedValue) {
      console.error(`Failed to parse stored value: ${storedValue}`);
    }
    return undefined;
  }
};

export function useSessionStorage<StoredType>(key: string):
    [StoredType | undefined, (newValue: StoredType | null) => void] {
  const [value, setValue] = useState<StoredType | undefined>(
    () => parseValueFromStorage(sessionStorage.getItem(key)));

  const handleStorageEvent = useCallback((event: StorageEvent) => {
    if (event.storageArea === sessionStorage && event.key === key) {
      setValue(parseValueFromStorage(event.newValue));
    }
  }, [key, setValue]);

  useEffect(() => {
    window?.addEventListener("storage", handleStorageEvent);
    return () => {
      window?.removeEventListener("storage", handleStorageEvent);
    };
  }, [handleStorageEvent]);

  const storeValue = useCallback((newValue: StoredType | null) => {
    if (newValue) {
      sessionStorage.setItem(key, JSON.stringify(newValue));
    } else {
      sessionStorage.removeItem(key);
    }
    setValue(newValue || undefined);
  }, [key, setValue]);

  return [value, storeValue];
}

export const dateTimeFormatter = new Intl.DateTimeFormat(
  'en-US', { dateStyle: 'full', timeStyle: 'short' });

  
export const NoWrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: { maxWidth: 'none' },
});

const filePickerOpts = {
  types: [
    {
      accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
    },
  ],
};

export function useDownloadUrl(url: string) {
  const authHeaders = useRecoilValue(authHeadersAtom);
  return useCallback(async () => {
    const headers = {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ...authHeaders,
    };
    const fileHandle = await window.showSaveFilePicker(filePickerOpts);
    const writable = await fileHandle.createWritable();
    const response = await axios.get(url, { headers, responseType: 'blob' });

    // Write the contents of the file to the stream.
    await writable.write(response.data);

    // Close the file and write the contents to disk.
    await writable.close();
  }, [authHeaders, url]);
}    

export class ApiError extends Error {
  readonly httpStatus?: number;

  constructor(message?: string, status?: number) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);

    this.httpStatus = status;
  }
}
