import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  Button,
  CircularProgress,
  Stack, Box,
  Typography
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import { NoWrapTooltip } from '../Common';
import { selectModeledBatch } from '../api/BatchApi';
import { useModelingProgress } from '../api/ModelingProgress';

export function ModelingProgressSpinner({modeledBatchUuid}: {modeledBatchUuid: string}) {
  const modelingProgress = useModelingProgress(modeledBatchUuid);
  const modeledBatch = useRecoilValue(selectModeledBatch(modeledBatchUuid));

  if (!modelingProgress.completed) {
    const { successCount, errors } = modelingProgress;
    return <div style={{position: 'relative'}}>
      <CircularProgress size="100px" style={{ position: 'absolute' }}/>
      <Box display="flex" alignItems="center" style={{ position: 'absolute', left: '20px', top: '30px' }}>
        <Stack>
          <Stack direction="row">
            <Typography fontSize="small">{successCount}&nbsp;&#x2714;</Typography>
            {!!Object.values(errors).length &&
              <NoWrapTooltip arrow placement="bottom-start"
                  title={Object.values(errors).join('\n')}
                  sx={{ whiteSpace: 'pre-line' }}>
                <ErrorIcon fontSize='small' sx={{pl: '10px'}} />
              </NoWrapTooltip>
            }
          </Stack>
          <Typography fontSize="small">{modeledBatch.batch.loanCount}&nbsp;Loans</Typography>
        </Stack>
      </Box>
    </div>;
  }
  return <Button component={Link} to={`/batches/${modeledBatchUuid}`} variant="contained">
    View Batch
  </Button>;
}
