import axios from 'axios';
import { useCallback } from 'react';
import { atom, atomFamily, selector, selectorFamily, useSetRecoilState } from 'recoil';

import { authHeadersAtom } from './Auth';
import { ModeledBatch, ModeledBatchSummary, ModeledBatchList } from './ApiTypes';
import { ApiError } from '../Common';

const batchRequestId = atomFamily<number, string>({
  key: 'BatchRequestId',
  default: 0,
});

const batchListRequestId = atom<number>({
  key: 'BatchListRequestId',
  default: 0,
})

export const selectModeledBatch = selectorFamily<ModeledBatch, string>({
  key: 'BatchQuery',
  get: batchId => async ({get}) => {
    get(batchRequestId(batchId)); // Add request ID as a dependency.
    const { data } =
      await axios.get<ModeledBatch>(`/api/v1/modeled/batch/${batchId}`,
          { headers: get(authHeadersAtom) })
        .catch((error) => {
          const msg = `Failed to query Batch(${batchId}): ${error.response?.statusText}`;
          console.error(msg);
          throw new ApiError(msg, error.response?.status);
        });
    if (typeof(data) === 'string') {
      console.log(`Received string ModeledBatch data: ${data}`)
      try {
        return JSON.parse(String(data));
      } catch (error) {
        console.error(error);
        throw Error('Invalid JSON response from API backend');
      }
    }
    return data;
  },
});

export function useRefreshBatch(batchId: string) {
  // Can be used to retry loading a batch if it has previously failed.
  const setBatchRequestId = useSetRecoilState(batchRequestId(batchId));
  return useCallback(() => {
    setBatchRequestId(requestId => requestId + 1);
  }, [setBatchRequestId]);
}

export const selectModeledBatchList = selector<ModeledBatchSummary[]>({
  key: 'BatchList',
  get: async ({get}) => {
    get(batchListRequestId);
    const response =
      await axios.get<ModeledBatchList>('/api/v1/modeled/batches', { headers: get(authHeadersAtom) })
        .catch((error) => {
          const msg = `Failed to query Batch List: ${error.response?.statusText}`;
          console.error(msg);
          throw new ApiError(msg, error.response?.status);
        });
    return response.data.modeledBatches;
  },
});

export function useRefreshBatchList() {
  // Can be used to force a retry of Batch List request in case of error.
  const setBatchListRequestId = useSetRecoilState(batchListRequestId)
  return useCallback(() => {
    setBatchListRequestId(requestId => requestId + 1);
  }, [setBatchListRequestId]);
}
