import { useCallback, useEffect } from 'react';
import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { atom, useSetRecoilState } from 'recoil';

import { useSessionStorage } from '../Common';
import { useNavigate } from 'react-router-dom';

interface SessionInfo {
  username: string;
  tokenData: TokenData;
}

interface TokenData {
  token_type: string;
  token: string;
  expires_in: number;
}

interface LoginInfo {
  username: string;
  password: string;
}

export const authHeadersAtom = atom<AxiosRequestHeaders>({
  key: 'AuthHeaders',
  default: {},
});

export const usernameAtom = atom<string>({
  key: 'Username',
  default: '',
});

export function useLogin() {
  const [sessionInfo, setSessionInfo] = useSessionStorage<SessionInfo>('jwtTokenData');
  const setAuthHeaders = useSetRecoilState(authHeadersAtom);
  const setUsername = useSetRecoilState(usernameAtom);

  useEffect(() => {
    if (sessionInfo?.tokenData?.token_type && sessionInfo?.tokenData?.token) {
      setAuthHeaders({
          Authorization: `${sessionInfo?.tokenData?.token_type} ${sessionInfo?.tokenData?.token}`,
      });
      setUsername(sessionInfo?.username || '');
    } else {
      setAuthHeaders({});
      setUsername('');
    }
  }, [sessionInfo, setAuthHeaders, setUsername])

  const doLogin = useCallback((loginInfo: LoginInfo) => {
    axios.post<TokenData>('/login', loginInfo)
      .then(({status, statusText, data}: AxiosResponse<TokenData>) => {
        if (status === 200) {
          setSessionInfo({ username: loginInfo.username, tokenData: data });
        } else {
          setSessionInfo(null);
          console.error(`Error status, /login POST response: ${statusText}`);
        }
      })
      .catch((error) => { console.error(`Login error: ${error}`) });

  }, [setSessionInfo]);

  return doLogin;
}

export function useLogout() {
  const [, setSessionInfo] = useSessionStorage<SessionInfo>('jwtTokenData');
  const setAuthHeaders = useSetRecoilState(authHeadersAtom);
  const setUsername = useSetRecoilState(usernameAtom);
  const navigate = useNavigate();

  return useCallback(() => {
    setSessionInfo(null);
    setAuthHeaders({});
    setUsername('');
    navigate('/');
  }, [setSessionInfo, setAuthHeaders, setUsername, navigate]);
}
