import axios from 'axios';
import { useCallback } from 'react';

import { selector, useRecoilValue } from 'recoil';

import { LoanModelList } from './ApiTypes';
import { authHeadersAtom } from './Auth';
import { ApiError } from '../Common';

export function useUploadLoanData(loanData: FormData) {
  const headers = useRecoilValue(authHeadersAtom);

  return useCallback(async () => {
    return axios.post('/api/v1/upload_loans', loanData, { headers })
      .catch((error) => {
        const msg = `Failed to upload loan data: ${error.response?.statusText}`;
        console.error(msg);
        throw msg
      });
  }, [headers, loanData]);
}

export const selectLoanModelList = selector<LoanModelList>({
  key: 'ModelList',
  get: ({get}) => {
    return axios.get<LoanModelList>('/api/v1/models', { headers: get(authHeadersAtom) })
      .catch((error) => {
        const msg = `Failed to query Batch List: ${error.response?.statusText}`;
          console.error(msg);
          throw new ApiError(msg, error.response?.status);
      })
      .then((response) => response.data);
  }
})
