import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
    Paper, Stack, Collapse, Box,
    Table, TableBody, TableHead, TableRow,
    Divider, Typography, IconButton, TextField
} from '@mui/material';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FilterListIcon from '@mui/icons-material/FilterList';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { dateTimeFormatter, NoWrapTooltip, useDownloadUrl } from '../Common';
import { selectModeledBatch, useRefreshBatch } from '../api/BatchApi';
import { ModeledLoan, LineItem } from '../api/ApiTypes';

function LoanRow({loan, backgroundColor}: {loan: ModeledLoan, backgroundColor: string}) {
    const [open, setOpen] = useState(false);
    const [showAllLineItems, setShowAllLineItems] = useState(false);
    const downloadLoan = useDownloadUrl(`/api/v1/download_excel_for_modeled_loan/${loan.uuid}`);

    // For now, just grab the first weighted_sums, we assume there's only one.
    const lineItems: LineItem[] = loan.weightedSums?.[0]?.lineItems || [];

    return <>
      <TableRow
          sx={{ '& > *': { borderBottom: '10px' } }}
          style={{ 'backgroundColor': backgroundColor }}
          onClick={() => setOpen(!open)}>
        <TableCell sx={{ py: '3px' }}>
          <Stack direction="row">
            <IconButton size="small">
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Tooltip arrow placement="bottom-start" title="Download Excel Sheet For Loan">
              <DownloadIcon onClick={downloadLoan} sx={{mt: '6px'}} />
            </Tooltip>
            {
              loan.errors?.length > 0 &&
                <NoWrapTooltip arrow placement="bottom-start"
                    title={loan.errors.join('\n')}
                    sx={{ whiteSpace: 'pre-line' }}>
                  <ErrorIcon sx={{ p: '5px' }} />
                </NoWrapTooltip>
            }
          </Stack>
        </TableCell>
        <TableCell sx={{ py: '3px' }}>{loan.assetId}</TableCell>
        <TableCell sx={{ py: '3px' }} style={{ whiteSpace: 'nowrap' }}>{
          !!loan.weight ? `${loan.modelType} (Weight ${loan.weight})` : loan.modelType
        }</TableCell>
        <TableCell sx={{ py: '3px' }}>{loan.ltv}</TableCell>
        <TableCell sx={{ py: '3px' }}>{loan.cvf}</TableCell>
        <TableCell sx={{ py: '3px' }}>{loan.dsc}</TableCell>
        <TableCell sx={{ py: '3px' }}>{loan.ytm}</TableCell>
        <TableCell sx={{ py: '3px' }}>{loan.spread}</TableCell>
        <TableCell sx={{ py: '3px' }}>{loan.price}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto">
            <Table sx={{
                background: '#b7cddb',
                borderRadius: '10px',
                pb: '10px',
                m: '10px',
                width: 'fit-content',
                '& tr > *': { paddingX: '30px' },
              }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ py: 0 }}>Line Item</TableCell>
                  <TableCell sx={{ py: 0 }}>Weight</TableCell>
                  <TableCell sx={{ py: 0 }}>Value</TableCell>
                  <TableCell sx={{ py: 0 }}>
                    Weighted Value
                    <Tooltip title="Show/Hide Zero-valued Line Items">
                      <IconButton size="small" onClick={() => setShowAllLineItems(!showAllLineItems)}>
                        {showAllLineItems ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{
                [`& tr .${tableCellClasses.root}`]: { borderBottom: 'none' },
                [`& tr:last-child .${tableCellClasses.root}`]: { paddingBottom: '5px' },
              }}>
                {lineItems.map((lineItem) => {
                  if (showAllLineItems || (lineItem.value !== 0 && lineItem.weight !== 0)) {
                      const rowStyle = lineItem.active ? {} : {
                        background: 'lightgrey',
                        fontStyle: 'italic',
                        fontWeight: 'lighter',
                        fontSize: 'small',
                      };
                      return <TableRow key={lineItem.name} sx={{'& td': rowStyle }}>
                        <TableCell sx={{ pt: '2px', pb: 0 }} className="nowrap">{lineItem.name}</TableCell>
                        <TableCell sx={{ pt: '2px', pb: 0 }}>{lineItem.weight}</TableCell>
                        <TableCell sx={{ pt: '2px', pb: 0 }}>{lineItem.value}</TableCell>
                        <TableCell sx={{ pt: '2px', pb: 0 }}>
                          {lineItem.value * lineItem.weight}
                          {lineItem.active ? null : <>&nbsp;(Disabled)</>}
                        </TableCell>
                      </TableRow>;
                  }
                  return <React.Fragment key={lineItem.name}></React.Fragment>;
                })}
                <TableRow sx={{ height: '10px' }} />
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>;
}

export default function BatchDetails() {
    const { batchUuid } = useParams();
    const modeledBatch = useRecoilValue(selectModeledBatch(batchUuid || ''));
    const refreshBatch = useRefreshBatch(batchUuid || '');
    const [assetFilter, setAssetFilter] = useState('');
    const downloadBatch = useDownloadUrl(`/api/v1/download_excel_for_modeled_batch/${modeledBatch.uuid}`);

    // Force a refresh on initial render.
    useEffect(refreshBatch, [refreshBatch]);

    if (!modeledBatch) {
      return <h1>Error loading batch!</h1>;
    }

    const loanRows: JSX.Element[] = []
    let lastAssetId = undefined;
    let backgroundColor = 'white';
    for (const loan of modeledBatch.loans.filter(
        (loan) => !assetFilter || loan.assetId.search(assetFilter) !== -1)) {
      if (lastAssetId !== loan.assetId) {
        backgroundColor = backgroundColor === 'white' ? 'lightgrey' : 'white';
      }
      lastAssetId = loan.assetId;
      loanRows.push(
        <LoanRow key={loan.uuid} loan={loan} backgroundColor={backgroundColor} />
      )
    }

    return <>
      <Stack spacing={2} direction="row" sx={{p: '10px'}}>
        <Tooltip arrow placement="bottom-start" title="Download Excel Sheet For Batch">
          <DownloadIcon onClick={downloadBatch} />
        </Tooltip>
        {
          modeledBatch.errors?.length > 0 ?
            <NoWrapTooltip
                arrow placement="bottom-start"
                title={modeledBatch.errors.join('\n')}
                sx={{whiteSpace: 'pre-line'}}>
              <ErrorIcon />
            </NoWrapTooltip>
            : <ErrorIcon sx={{ '&': { visibility: 'hidden' }}} />
        }
        <Box>
          <Typography component="span">Batch(</Typography>
          <Typography sx={{ fontFamily: 'Monospace' }} component="span">
            {modeledBatch.uuid.slice(0, 6)}
          </Typography>
          <Typography component="span">)</Typography>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography>
          {modeledBatch.loans.length}/{modeledBatch.loanCount} Loans
        </Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography>File: {modeledBatch.batch.filename}</Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography>Desc: {modeledBatch.batch.description}</Typography>
      </Stack>
      <Stack spacing={2} direction="row" sx={{p: '10px'}}>
        <Typography>Provider: {modeledBatch.batch.provider}</Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography>Model: {modeledBatch.loanModelName}</Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography>
          Processed: {dateTimeFormatter.format(new Date(modeledBatch.processingCompleted))}
        </Typography>
      </Stack>
      <Paper elevation={2} sx={{ p: '10px', width: 'fit-content', bgcolor: '#fff' }}>
        <Typography sx={{ p: '10px' }} variant="h3">Loans In Batch</Typography>
        <Paper elevation={3} sx={{ p: '10px', mb: '20px', width: 'fit-content' }}>
          <Stack spacing={2} direction="row" sx={{ p: '10px' }}>
            <FilterListIcon fontSize="large" sx={{mt: 1}} />
            <TextField label="Asset ID" onChange={(e) => setAssetFilter(e.target.value)} />
          </Stack>
        </Paper>
        <Table sx={{ minWidth: 650, maxWidth: 'fit-content', m: '10px' }} aria-label="loan table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Tooltip title="Expand Line Items">
                  <ListAltIcon sx={{mt: 1}} />
                </Tooltip>
              </TableCell>
              <TableCell>Asset ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>LTV</TableCell>
              <TableCell>CVF</TableCell>
              <TableCell>Amortizing DSC</TableCell>
              <TableCell>YTM</TableCell>
              <TableCell>Spread</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loanRows}
          </TableBody>
        </Table>
      </Paper>
  </>;
}