import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useRecoilValue } from 'recoil';

import { 
  Button,
  Box,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Checkbox, FormControlLabel
} from '@mui/material';

import { authHeadersAtom } from '../api/Auth';
import { selectLoanModelList } from '../api/UploadApi';

import { requireAuth } from './Auth';
import { ModelingProgressSpinner } from './ModelingProgressSpinner';

type UploadResponse = {
  batch_uuid?: string;
  modeled_batch_uuid?: string;
};

const UploadLoans = requireAuth(() => {
  const [uploading, setUploading] = useState(false);
  const [status, setStatus] = useState<string>('Ready');
  const [batchUuid, setBatchUuid] = useState<string>();
  const authHeaders = useRecoilValue(authHeadersAtom);
  const loanModelList = useRecoilValue(selectLoanModelList);
  const [applyModel, setApplyModel] = useState<boolean>(false);
  const [modelParams, setModelParams] = useState<string[]>([]);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const loansFileField = 
      event.currentTarget.elements.namedItem('loansFile') as HTMLInputElement;
    const headers = {
      'Content-Type': 'multipart/form-data',
      ...authHeaders,
    };
    setUploading(true);
    axios.post<UploadResponse>('/api/v1/upload_loans', new FormData(event.currentTarget),
           { headers })
      .then((response) => {
        if (response.status === 200) {
          // Clear loansFile to mitigate accidental re-upload of same exact file.
          loansFileField.value = '';
          setBatchUuid(response.data?.modeled_batch_uuid);
          setStatus('Upload Successful!');
        } else {
          setStatus(`${response.statusText} (${response.status})`);
        }
      })
      .catch((error) => {
        console.error(`Failed to POST loan data: ${error}`);
        if (error?.response.status === 401) {
          setStatus('Authentication expired, please log out and log back in.');
        } else {
          setStatus(`${error?.response.statusText} (${error?.response.status}): ${error?.response.data}`);
        }
      })
      .finally(() => setUploading(false));
  }, [authHeaders, setStatus, setUploading]);

  const selectModel = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    for (const loanModel of loanModelList.models) {
      if (loanModel.name === event.target.value) {
        setModelParams(loanModel.parameters);
        return;
      }
    }
  }, [loanModelList, setModelParams]);

  return <div style={{width: 'fit-content', paddingLeft: '10px'}}>
    <Typography variant="h6">Status: {status}</Typography><br />
    <Box component="form" onSubmit={handleSubmit}>
    <Stack spacing={2}>
      <TextField
        required
        label="CSV/Excel File"
        InputLabelProps={{ shrink: true }}
        name="loansFile"
        type="file" />
      <TextField select required label="Provider" name="provider">
        {
          loanModelList.providers.map((providerName) =>
            <MenuItem value={providerName}>{providerName}</MenuItem>
          )
        }
      </TextField>
      <TextField label="Description (optional)" name="description" />
      <Box sx={{ border: 1, borderRadius: 2, p: '10px', borderColor: 'gray' }}>
        <Stack spacing={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={applyModel}
                onChange={(event) => setApplyModel(event.target.checked)}
              />
            }
            label="Apply Model"
          />
          <TextField disabled={!applyModel} select required label="Model" name="loanModel" onChange={selectModel}>
            {
              loanModelList.models.map((loanModel) => 
                <MenuItem value={loanModel.name}>{loanModel.name}</MenuItem>
              )
            }
          </TextField>
          {
            modelParams.map((paramName) => 
              paramName.toLowerCase().startsWith('is') ?  // TODO: Kind of janky :(
              <FormControlLabel disabled={!applyModel} key={paramName} label={paramName} control={
                <Checkbox name={`modelParam:${paramName}`} />} /> : null)
          }
        </Stack>
      </Box>
      <Stack spacing={2} direction="row">
        <Button type="submit" variant="contained" disabled={uploading}>Upload</Button>
        <CircularProgress style={{ visibility: uploading ? "visible" : "hidden" }} />
        {!!batchUuid && <ModelingProgressSpinner modeledBatchUuid={batchUuid} />}
      </Stack>
    </Stack>
    </Box>
  </div>;
});

export default UploadLoans;
