import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import { LoginForm } from './components/Auth';
import BatchList from './components/BatchList';
import BatchDetails from './components/BatchDetails';
import UploadLoans from './components/UploadLoans';

import App from './App';

export default function AppRouter(): JSX.Element {
  return <Router>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="upload" element={<UploadLoans />} />
        <Route path="batches" element={<BatchList />}>
          <Route path=":batchUuid" element={<BatchDetails />} />
        </Route>
        <Route index element={<LoginForm />} />
      </Route>
    </Routes>
  </Router>;
}
