import React, {
  FormEvent,
  FormEventHandler,
  useCallback,
} from 'react';

import { Button, Stack, TextField } from '@mui/material';
import { useLocation, Navigate } from "react-router-dom";

import { NavButton } from '../Common';

import { authHeadersAtom, useLogin, useLogout, usernameAtom } from '../api/Auth';
import { useRecoilValue } from 'recoil';

export function LoginForm(): JSX.Element {
  const doLogin = useLogin();
  const authHeaders = useRecoilValue(authHeadersAtom);

  const handleSubmit: FormEventHandler = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formControls = event.currentTarget.elements;
    const username = (formControls.namedItem('username') as HTMLInputElement).value;
    const password = (formControls.namedItem('password') as HTMLInputElement).value;

    if (username && password) {
      doLogin({
        username,
        password,
      });
    }
  }, [doLogin]);
  
  if (authHeaders?.Authorization) {
    // If already authenticated, automatically navigate to batches view.
    return <Navigate to="/batches" replace />;
  }

  return <div style={{width: 'fit-content', paddingLeft: '10px'}}>
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
    <Stack spacing={2}>
    <TextField required id="username" label="Username" />
    <TextField required id="password" type="password" label="Password" />
    <Button type="submit" variant="contained">Login</Button>
    </Stack>
    </form>
  </div>;
}

export function AuthButton(): JSX.Element {
  const username = useRecoilValue(usernameAtom);
  const doLogout = useLogout();
  if (username) {
    return <Button onClick={doLogout} size="large" variant="outlined">
      Logout ({username})
    </Button>;
  }
  return <NavButton to="/">Login</NavButton>;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const loggedIn = !!useRecoilValue(usernameAtom);
  const location = useLocation();
  
  if (!loggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  
  return children;
}
export const requireAuth = (WrappedComponent: React.FC) => ({...props}) => {
  return <RequireAuth>
    <WrappedComponent {...props} />
  </RequireAuth>
}