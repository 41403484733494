import axios from 'axios';
import { useCallback, useState } from 'react';
import {
  atom, atomFamily,
  selector, selectorFamily,
  useRecoilValue, useSetRecoilState, noWait
} from 'recoil';

import { ModelingProgress } from './ApiTypes';
import { authHeadersAtom } from "./Auth";
import { ApiError } from '../Common';
import { useEffect } from 'react';

const PROGRESS_REFRESH_INTERVAL_MS = 3000;
const DEFAULT_MODELING_PROGRESS = {
  completed: false, asOf: new Date(), successCount: 0, errors: {} };

const modelingProgressRequestId = atomFamily<number, string>({
  key: 'ModelingProgressRequestId',
  default: 0,
});

const modelingProgressListRequestId = atom<number>({
  key: 'ModelingProgressListRequestId',
  default: 0,
});

const selectModelingProgress = selectorFamily<ModelingProgress, string>({
  key: 'ModelingProgress',
  get: (batchId) => async ({get}) => {
    get(modelingProgressRequestId(batchId));  // Support refresh by updating RequestId
    console.log(`Requesting progress for  ${batchId}`)
    const { data } =
      await axios.get<ModelingProgress>(`/api/v1/progress/batch/${batchId}`,
          { headers: get(authHeadersAtom) })
        .then((response) => {
          if (response.status === 204) {
            console.log(`No progress data for ${batchId}, defaulting.`)
            return { data: DEFAULT_MODELING_PROGRESS };
          }
          return response;
        })
        .catch((error) => {
          const msg = `Failed to query Batch(${batchId}): ${error.response?.statusText}`;
          console.error(msg);
          throw new ApiError(msg, error.response?.status);
        });

    if (typeof(data) === 'string') {
      // Not sure why axios does this sometimes...
      console.log(`Received string ModelingProgress data: ${data}`)
      try {
        return JSON.parse(String(data));
      } catch (error) {
        console.error(error);
        throw Error('Invalid JSON response from API backend');
      }
    }
    return data;
  },
});

const selectModelingProgressList = selector<Record<string, ModelingProgress>>({
  key: 'ModelingProgressList',
  get: async ({get}) => {
    get(modelingProgressListRequestId);  // Support refresh by updating RequestId
    console.log(`Requesting progress list`);
    const { data } =
      await axios.get<Record<string, ModelingProgress>>(`/api/v1/progress`,
          { headers: get(authHeadersAtom) })
        .catch((error) => {
          const msg = `Failed to query progress list: ${error.response?.statusText}`;
          console.error(msg);
          throw new ApiError(msg, error.response?.status);
        });

    if (typeof(data) === 'string') {
      // Not sure why axios does this sometimes...
      console.error(`Received string ModelingProgress list data: ${data}`)
      try {
        return JSON.parse(String(data));
      } catch (error) {
        console.error(error);
        throw Error('Invalid JSON response from API backend');
      }
    }
    return data;
  },
});

export function useModelingProgress(batchId: string): ModelingProgress {
  const [latestProgress, setLatestProgress] =
      useState<ModelingProgress>(DEFAULT_MODELING_PROGRESS);
  const { state: progressState, contents: progressContents } = 
      useRecoilValue(noWait(selectModelingProgress(batchId)));
  const setRequestId = useSetRecoilState(modelingProgressRequestId(batchId));
  const refreshModelingProgress =
      useCallback(() => setRequestId((requestId) => requestId + 1), [setRequestId]);
  
  useEffect(() => {
    if (progressState === 'hasValue') {
      setLatestProgress(progressContents);
      if (progressContents === DEFAULT_MODELING_PROGRESS) {
        console.log(`No progress data for ${batchId}, will retry after delay...`);
        const timer = setTimeout(refreshModelingProgress, PROGRESS_REFRESH_INTERVAL_MS);
        return () => clearTimeout(timer);
      }
    }
  }, [batchId, setLatestProgress, refreshModelingProgress, progressState, progressContents]);

  useEffect(() => {
    if (latestProgress !== DEFAULT_MODELING_PROGRESS && !latestProgress.completed) {
      console.log(`Will refresh progress for ${batchId} after delay...`)
      const timer = setTimeout(refreshModelingProgress, PROGRESS_REFRESH_INTERVAL_MS);
      return () => clearTimeout(timer);
    }
  }, [batchId, latestProgress, refreshModelingProgress]);

  return latestProgress;
}

export function useModelingProgressList(): Record<string, ModelingProgress> {
  const [latestProgress, setLatestProgress] = useState<Record<string, ModelingProgress>>({});
  const { state: progressState, contents: progressContents } = 
    useRecoilValue(noWait(selectModelingProgressList));
  const setRequestId = useSetRecoilState(modelingProgressListRequestId);
  const refreshModelingProgress =
    useCallback(() => setRequestId((requestId) => requestId + 1), [setRequestId]);

  useEffect(() => {
    if (progressState === 'hasValue') {
      setLatestProgress(progressContents);
    }
  }, [setLatestProgress, progressState, progressContents]);

  useEffect(() => {
    if (!latestProgress.completed) {
      console.log(`Will refresh progress list after delay...`)
      const timer = setTimeout(refreshModelingProgress, PROGRESS_REFRESH_INTERVAL_MS);
      return () => clearTimeout(timer);
    }
  }, [latestProgress, refreshModelingProgress]);

  return latestProgress;
}
