import React from 'react';

import { useRecoilValue } from 'recoil';
import { useNavigate, useParams, Outlet } from 'react-router-dom';

import { Stack } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { dateTimeFormatter, NoWrapTooltip } from '../Common';
import { selectModeledBatchList } from '../api/BatchApi';
import { requireAuth } from './Auth';
import { ModeledBatchSummary } from '../api/ApiTypes';

function BatchRow({modeledBatch}: {modeledBatch: ModeledBatchSummary}) {
  const navigate = useNavigate();
  return <TableRow onClick={() => navigate(modeledBatch.uuid)}>
    <TableCell>
      <Stack direction="row" spacing={2}>
        {!!modeledBatch.errors.length &&
          <NoWrapTooltip
              arrow placement="bottom-start"
              title={modeledBatch.errors.join('\n')}
              sx={{ whiteSpace: 'pre-line' }}>
            <ErrorIcon />
          </NoWrapTooltip>
        }
      </Stack>
    </TableCell>
    <TableCell>{modeledBatch.loanCount}/{modeledBatch.batch.loanCount}</TableCell>
    <TableCell>{modeledBatch.batch.filename}</TableCell>
    <TableCell>{modeledBatch.batch.description}</TableCell>
    <TableCell>{modeledBatch.batch.provider}</TableCell>
    <TableCell>{modeledBatch.loanModelName}</TableCell>
    <TableCell>{dateTimeFormatter.format(new Date(modeledBatch.processingCompleted))}</TableCell>
  </TableRow>;
}

const BatchList = requireAuth(() => {
  const { batchUuid } = useParams();
  const batchListValue = useRecoilValue(selectModeledBatchList);

  if (batchUuid) return <Outlet />;

  return <Table style={{width: 'fit-content'}}>
    <TableHead>
      <TableRow>
        <TableCell>Errors</TableCell>
        <TableCell>Loans</TableCell>
        <TableCell>Filename</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Provider</TableCell>
        <TableCell>Model</TableCell>
        <TableCell>Processed Date</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        batchListValue.map((modeledBatch: ModeledBatchSummary) =>
          <BatchRow key={modeledBatch.uuid} modeledBatch={modeledBatch} />
        )
      }
    </TableBody>
  </Table>
});

export default BatchList;
